import React, { useContext,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsFillPencilFill } from 'react-icons/bs';
import { AiFillDelete } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import DeleteModal from '../deleteModal/DeleteModal';
import axios from 'axios';

import { contextMenuItems, employeeGrid, employeeData } from './config/EmployeeConfig';
import '../css/view.css';

// import { employeeData, employeeGrid } from "../../assets/dummy";
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import StateContext from '../../contexts/ContextProvider';
import NewToggle from '../newToggle/NewToggle';
import EmployeeToggle from './EmployeeToggle';
import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
import {
  AppBar,
  Divider,
  Fade,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import { Button, CircularProgress, Toolbar, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse",
  },
  cell: {
    padding: "8px 0px 8px 16px",
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px",
    textAlign: "start",
  },
  busy: {
    color: 'red',
    fontSize: '15px',
    fontWeight: "500",
  },
  free: {
    fontSize: '15px',
    fontWeight: "500",
    color: 'green',
  },
}));

const Employees = () => {
	const { employees, setEmployees,distributor, setDistributor, setEmployeeId, employeeInfo, setEmployeeInfo, setOpen } = useContext(StateContext);
  const token = sessionStorage.getItem("token");

  console.log(distributor, "distributor")
	useEffect(async () => {
		await axios.get('https://api.orbitmart.co.in/api/v1/auth/allEmployee').then((res) => setEmployees(res.data.data));
	}, []);
	const [searchQuery, setSearchQuery] = useState("");
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10);

	const navigate = useNavigate();

	const addPage = () => {
		navigate('/employees/add');
	};
	const gridItemAction = (props) => (
		<div className="flex items-center justify-center gap-2">
			<h1
				style={{ color: '#8be78b', display: 'flex' }}
				className="font-small leading-tight text-xl mt-0 mb-2 "
				onClick={(e) => setEmployeeInfo(props)}>
				<Link style={{ marginRight: 'auto' }} to="/employees/edit">
					<BsFillPencilFill />
				</Link>
			</h1>
			<h1
				style={{ color: '#03c9d7', display: 'flex' }}
				className="font-small leading-tight text-xl mt-0 mb-2 "
				onClick={(e) => setEmployeeInfo(props)}>
				<Link style={{ marginLeft: 'auto', marginRight: 'auto' }} to="/employees/view">
					<FiEye />
				</Link>
			</h1>
			<h1
				style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
				className="font-small leading-tight text-xl mt-0 mb-2 "
				onClick={() => {
					setEmployeeId(props._id);
				}}>
				<DeleteModal />
			</h1>
		</div>
	);

	console.log(employees, "employees")

	const gridEmpStatus = (emp) => {
		let adminActiveInactive = emp.adminActiveInactive;
		return (
			<div className="flex items-center">
				<EmployeeToggle emp={emp} adminActiveInactive={adminActiveInactive} />
			</div>
		);
	};

	const handleChangePage = (event, newPage) => {
		setCurrentPage(newPage);
	  };
	  const handleChangeRowsPerPage = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setCurrentPage(0);
	  };
	  const changeToFirstPage = () => {
		setLoading(true);
		setCurrentPage(0);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToPrevPage = () => {
		setLoading(true);
		setCurrentPage(currentPage - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToNextPage = () => {
		setLoading(true);
		setCurrentPage(currentPage + 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const changeToLastPage = () => {
		setLoading(true);
		setCurrentPage(Math.ceil(employees?.length / pageSize) - 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };

    
  const fetchDistributor = async () => {
    const header = {
      "x-token": token,
    };
    try {
      const response = await axios.get(
        "https://api.orbitmart.co.in/api/v1/auth/admin/allDistributor",
        {
          headers: header,
        }
      );
      setDistributor(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => fetchDistributor(), []);
  useEffect(() => {}, [distributor]);

	const filteredData = employees
  ?.filter((employee) =>
  employee?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  employee?.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
  employee?.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
  employee?.pincode.toLowerCase().includes(searchQuery.toLowerCase())

  )
  .slice(currentPage * pageSize, currentPage * pageSize + pageSize);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0); // Reset to the first page whenever a new search is made
  };
	return (
    <div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
      <div class="flex space-x-2 m-4 justify-end">
        <button type="button" onClick={() => addPage()} className="addBtn">
          New Record
        </button>
      </div>
      <AppBar
        position="static"
        style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6">employees ({employees?.length})</Typography>
          <span
            className="flex space-x-2 justify-end"
            style={{ background: "white", borderRadius: "6px" }}
          >
            <input
              style={{
                padding: "4px 10px",
                outline: "none",
                borderRadius: "6px 0 0 6px",
                color: "black",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <BiSearch
              style={{
                fontSize: "25px",
                color: "gray",
                cursor: "pointer",
                margin: "auto",
                paddingRight: "5px",
              }}
            />{" "}
            {/*  this is the search icon */}
          </span>
        </Toolbar>
      </AppBar>
      {/* {console.log({ filteredData })} */}
      <div style={{ marginTop: "10px" }}></div>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>User Name</TableCell>
              <TableCell className={classes.cellRow}>Email</TableCell>
              <TableCell className={classes.cellRow}>Mobile</TableCell>
              {/* <TableCell className={classes.cellRow}>Gender</TableCell> */}
              <TableCell className={classes.cellRow}>Pin Code</TableCell>
              <TableCell className={classes.cellRow}>Active</TableCell>
              <TableCell className={classes.cellRow}>Available</TableCell>
              {/* {headerValue == "" ? null : <TableCell className={classes.cellRow}>{headerValue}</TableCell>} */}
              <TableCell className={classes.cellRow} style={{textAlign: 'center'}}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((item) => (
                <TableRow key={item._id}>
                  <TableCell
                    className={classes.cell}
                  >{`${item.firstName} ${item.lastName}`}</TableCell>
                  <TableCell className={classes.cell}>{item.email}</TableCell>
                  <TableCell className={classes.cell}>{item.mobile}</TableCell>
                  {/* <TableCell className={classes.cell}>{item.gender}</TableCell> */}
                  <TableCell className={classes.cell}>{item.pincode}</TableCell>
                  <TableCell className={classes.cell}>
                    {gridEmpStatus(item)}{" "}
                  </TableCell>
                  <TableCell className={`${classes.cell} ${item.employeeStatus === true ? classes.busy : classes.free}`}>{item.employeeStatus === true ? "BUSY": "FREE"}</TableCell>
                  <TableCell className={classes.cell}>
                    {gridItemAction(item)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {employees.length > 0 ? (
          <TablePagination
            count={employees.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            ActionsComponent={() => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  onClick={changeToFirstPage}
                  disabled={currentPage === 0}
                >
                  <FirstPageOutlined />
                </Button>
                <Button onClick={changeToPrevPage} disabled={currentPage === 0}>
                  <NavigateBefore />
                </Button>
                <span className="myPage">{currentPage + 1}</span>
                <Button
                  onClick={changeToNextPage}
                  disabled={
                    currentPage >= Math.ceil(employees?.length / pageSize) - 1
                  }
                >
                  <NavigateNext />
                </Button>
                <Button
                  onClick={changeToLastPage}
                  disabled={
                    currentPage >= Math.ceil(employees?.length / pageSize) - 1
                  }
                >
                  <LastPageOutlined />
                </Button>
              </div>
            )}
          />
        ) : null}
      </TableContainer>
    </div>

    // 	<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
    // 		<Header title="Employees" />
    // 		{/* <Header category="Page" title="Employees" /> */}
    // 		{/* <div class="flex space-x-2 m-4 justify-end">
    //     <button type="button" onClick={() => addPage()} class="addButton">
    //       New Record
    //     </button>
    //   </div> */}
    // 		<GridComponent
    // 			id="gridcomp"
    // 			dataSource={employees}
    // 			width="auto"
    // 			allowPaging
    // 			allowSorting
    // 			allowExcelExport
    // 			allowPdfExport
    // 			pageSettings={{ pageCount: 5 }}
    // 			contextMenuItems={contextMenuItems}
    // 			selectionSettings={selectionsettings}
    // 			editSettings={editing}
    // 			toolbar={toolbarOptions}
    // 			allowFiltering={true}
    // 			filterSettings={{ ignoreAccent: true, type: 'Excel' }}>
    // 			<ColumnsDirective>
    // 				<ColumnDirective
    // 					field="_id"
    // 					headerText="ID"
    // 					textAlign="center"
    // 					isPrimaryKey={true}
    // 					visible={false}
    // 				/>
    // 				<ColumnDirective field="firstName" headerText="First Name" textAlign="center" width="100" />
    // 				<ColumnDirective field="lastName" headerText="Last Name" textAlign="center" width="100" />

    // 				<ColumnDirective field="email" headerText="Email" textAlign="Center" width="100" />
    // 				<ColumnDirective field="mobile" headerText="Mobile" textAlign="Center" width="100" />
    // 				<ColumnDirective field="pincode" headerText="Pin Code" textAlign="Center" width="100" />
    // 				<ColumnDirective
    // 					field="ActiveInactive"
    // 					headerText="Status"
    // 					textAlign="Center"
    // 					width="100"
    // 					template={gridEmpStatus}
    // 				/>
    // 				<ColumnDirective
    // 					field="_id"
    // 					headerText="Action"
    // 					textAlign="Center"
    // 					width="60"
    // 					template={gridItemAction}
    // 					allowFiltering={false}
    // 				/>
    // 			</ColumnsDirective>
    // 			<Inject
    // 				services={[
    // 					Resize,
    // 					Search,
    // 					ContextMenu,
    // 					Page,
    // 					Selection,
    // 					Toolbar,
    // 					Edit,
    // 					Sort,
    // 					Filter,
    // 					ExcelExport,
    // 					PdfExport
    // 				]}
    // 			/>
    // 		</GridComponent>
    // 	</div>
  );
};
export default Employees;
