import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import "../view.css";
import axios from "axios";
//DropdownNew
import Select from "react-select";
import { Alert, Stack } from "@mui/material";

const AddSubClassification = () => {
  const [types, setTypes] = useState([]);
  const [category, setCategory] = useState([]);
  const [classification, setClassification] = useState([]);
  const [data, setData] = useState([""]);
  const [newData, setNewData] = useState([]);
  const [value, setValue] = useState([""]);

  const [cValue, setCValue] = useState([]);
  const [caValue, setCaValue] = useState([]);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  function fetchCategory() {
    debugger;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/category`)
      .then((res) => {
        debugger;
        console.log(res.data);
        setCategory(res.data.data);
        if (caValue != null) {
          fetchClassifications();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function fetchClassifications() {
    debugger;
    const catid = caValue.value;
    const id = catid;
    axios
      .get(`https://api.orbitmart.co.in/api/v1/classification/bycategoryId/${id}`)
      .then((res) => {
        console.log(res.data);
        setClassification(res.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const classificationOp = classification.map((opt) => ({
    label: opt.title,
    value: opt._id,
  }));

  const categoryOp = category.map((opt) => ({
    label: opt.title,
    value: opt._id,
  }));

  // const getTypes = async () => {
  //   const res = await axios.get(
  //     `https://api.orbitmart.co.in/api/v1/classification`
  //   );
  //   setTypes(res.data.data);
  //   console.log(res.data.data);
  // };

  useEffect(() => {
    fetchCategory();
  }, [data, caValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/subClassification");
  };

  async function postData() {
    debugger;
    const finalData = {
      classificationId: cValue.value,
      title: value,
    };
    try {
      const res = await fetch(
        "https://api.orbitmart.co.in/api/v1/subclassification",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalData),
        }
      );
      if (!res.ok) {
        setError(true);
        setTimeout(() => {
          setError(false)
        }, 1000);
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      const data = await res.json();
      const result = {
        status: res.status + "-" + res.statusText,
        headers: {
          "Content-Type": res.headers.get("Content-Type"),
          "Content-Length": res.headers.get("Content-Length"),
        },
        data: data,
      };
      console.log(data);
      console.log(result);
      setAlert(true);
      setTimeout(() => {
        mainPage();
      }, 1000);
    } catch (error) {
      setError(true);
      setTimeout(() => {
      setError(false)
      }, 1000);
      console.log(error.message);
    }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Subclassification
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          class="addButton"
          onClick={postData}
          style={{ width: "8%" }}
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Category</label>
            <Select options={categoryOp} onChange={(opt) => setCaValue(opt)} />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Select Category</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="categoryId"
              value={data.categoryId}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              {category.map((typeget) => (
                <option value={typeget._id} key={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <Select
              options={classificationOp}
              onChange={(opt) => setCValue(opt)}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="classificationId"
              value={data.classificationId}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              {classification.map((typeget) => (
                <option value={typeget._id} key={typeget._id}>
                  {typeget.title}
                </option>
              ))}
            </select>
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Sub Classification</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="title"
              onChange={(e) => setValue(e.target.value)}
              placeholder="Enter SubClassification"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddSubClassification;
