import React from 'react'
import {
    TextField,
    Button,
    Typography,
    makeStyles,
    Paper,
    Grid,
    IconButton,
    Avatar,
    Input,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    AppBar,
    Toolbar,
    CircularProgress,
  } from "@material-ui/core";

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    submitButton: {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    textField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    avatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    editIcon: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: theme.palette.background.paper,
      borderRadius: "50%",
    },
    button: {
      marginRight: theme.spacing(2),
    },
    statusContainer: {
      display: "flex",
      alignItems: "center",
    },
  }));

const UserDetails = ({userInfo}) => {
    const classes = useStyles();
    console.log(userInfo, "userInfo")

    const dateStrLast = userInfo.updatedAt;
const dateLast = new Date(dateStrLast);

const optionsLast = { day: '2-digit', month: 'short', year: 'numeric' };

const formattedDateLast = dateLast.toLocaleDateString('en-GB', optionsLast).replace(/ /g, ' ');
console.log();

    const dateStr = userInfo.createdAt;
const date = new Date(dateStr);

const options = { day: '2-digit', month: 'short', year: 'numeric' };

const formattedDate = date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
console.log(formattedDate);
  return (
    <>
    <Paper className={classes.paper}>
            <Typography variant="h6" style={{color :"#66cdaa"}}>General Information</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={userInfo.firstName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.lastName}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="gender"
                  name="gender"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.gender}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Mobile"
                  name="mobile"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.mobile}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Email"
                  name="email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.email}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Status"
                  name="status"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.status}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Member Since"
                  name="date"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={formattedDate}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Recent Purchases"
                  name="Recent Purchases Date"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={formattedDateLast}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Address"
                  name="address"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.address}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                <TextField
                  label="District"
                  name="district"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.district}
                />
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Pincode"
                  name="pincode"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  disabled
                  value={userInfo.pincode}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3} style={{display: 'grid', placeItems: 'center'}}>
              <Avatar className={classes.avatar} src={userInfo.avatar[0]} alt="Uploaded Image" sx={{width: 24, height: 24, borderRadius: '50%'}} />
              </Grid> */}
            </Grid>
          </Paper>
    </>
  )
}

export default UserDetails