import React, { useState, useEffect, useContext } from "react";
// import "./tablecss.css";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
// import OrderDelete from "./config/OrderDelete";
// import { itemsGrid, itemsData, contextMenuItems } from "./config/OrderConfig";

// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
// import { Header } from "../../components";
import AssignedPopup from "./AssignedPopup";
import DeleteModal from "../../pages/deleteModal/DeleteModal";
import AssignPopup from "./AssignPopup";
import ConfirmModal from "./ConfirmModal";
import ShipPopup from "./ShipPopup";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
// import { gridConfirm,gridItemAction } from './config/OrderConfig'
import { Button } from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px 0 8px 16px", 
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "15px", 
    textAlign: "start",
  },
}));
// format date
const formatDate = (dateString) => {
  // Parse the date string
  const parsedDate = new Date(dateString);
 
  // Check if the parsed date is valid
  if (!isNaN(parsedDate.getTime())) {
 
    // Format the date as "DD/M/YYYY"
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();
 
    return `${day}/${month}/${year}`;
  } else {
    // If parsing fails, return the original date string
    return dateString;
  }
};

const Orders = () => {
  const distId = sessionStorage.getItem("id");
  const classes = useStyles();
  const {orderData, setOrderData, orderId, setOrderId } = useContext(StateContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('PENDING');
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [TotalData,setTotalData] = useState([])
	const { setViewData, viewData } = useContext(StateContext);
  const [length, setLength] = useState("")

console.log(orderId, "orderId")
  function fetchOrders() {
    axios

      .get(`https://api.orbitmart.co.in/api/v1/order`)
      .then((res) => {
        // let data=res.data.data.map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})
        // setTDatas(data)

        // let data = res.data
        //   .filter((or) => or.distributorId == distId)
        //   .reverse()
          // .map((itemId) => {
          //   itemId.grandTotal = getAmount(itemId);
          //   return itemId;
          // });
        setTotalData(res.data)
        console.log("hello orderdata", res.data.data);
        setOrderData(res.data.data);
        setLength(res.data.data.length)
      })
      .catch((err) => {
        console.log("orderdata error", err.message);
      });
  }
  useEffect(() => {
    fetchOrders();
  }, []);

  const itemView = (itemId) => {
	setViewData(itemId);
	console.log(itemId)
};

  // const filteredData = orderData.filter(item => item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase()));
  // console.log(filteredData, "filteredData filteredData")

useEffect(() => {
  const storedPage = sessionStorage.getItem("currentPageOrder");
  if (storedPage !== null) {
    setCurrentPage(parseInt(storedPage, 10));
  }

  const storedStatus = sessionStorage.getItem("selectedStatusorder");
  if (storedStatus !== null) {
    setSelectedStatus(storedStatus);
  }
}, []);
useEffect(() => {
  sessionStorage.setItem("currentPageOrder", currentPage.toString());
}, [currentPage]);

  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];


  const filteredOrders = []

//   const filteredOrders = selectedStatus ? orderData.filter((item) => item.orderStatus === selectedStatus): orderData;

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(filteredOrders?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(filteredOrders?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
  const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };


  const navigate = useNavigate();

  // const getAmount=(itemId)=>{
  //     return ((itemId.totalPrice)-(itemId.productDetails.map((pr)=>{

  //      if(pr?.productId?.discountType=='FIXED')
  //      {
  //        return  parseInt(pr.productId.customerDiscount)
  //      }
  //      else{
  //        return  (parseInt(pr.productId?.itemPrice)*parseInt(pr.productId?.customerDiscount!==""?pr.productId?.customerDiscount:0))/100
  //      }
  //    }).reduce((a,b)=>a+b))+ (((itemId.totalPrice*parseInt(itemId.productDetails[0].productId?.gst))/100))+20+itemId?.productDetails
  //    ?.map((pr) => {
  //      return parseInt(pr?.productId?.orbitMartCommission);
  //    })
  //    .reduce((a, b) => a + b)).toFixed(0)
  //    }
  const getAmount = (itemId) => {
    const subtotal = itemId?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b)||0;
    const customerDiscount = itemId?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr?.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b)||0;
    const GstLogic =
      ((subtotal - customerDiscount) *
        parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
      100||0;
    const DeliveryAmount = 0;
    const TotalAmount = (
      subtotal -
      customerDiscount +
      GstLogic +
      DeliveryAmount
    ).toFixed(0);
    return TotalAmount;
  };


  const filteredData = filteredOrders.map((item) => {
    const userName = item?.userId?.firstName ?? '';
    const order = item?.orderNo ?? '';
  
    return {
      ...item,
      userId: {
        ...item?.userId,
        firstName: userName,
      },
      orderNo: order,
    };
  }).filter((item) => {
    const firstNameMatches = item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    const orderNoMatches = item?.orderNo?.toString().includes(searchQuery);

    return firstNameMatches || orderNoMatches;
  });
  
  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

  const handleTotalAmount = (amount) => {
    if (typeof amount !== 'number') {
      return 'Invalid amount';
    }
    const totalAmount = amount
   return totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
  }

  const amount = TotalData?.totalPrice;
  const commission = TotalData?.totalCommission;
  const totalAmount = handleTotalAmount(amount)
  const totalCommission = handleTotalAmount(commission)
  // const addFilterBtn = status => setSelectedStatus(status);
const addFilterBtn = (status) => {
    setSelectedStatus(status);
    sessionStorage.setItem('selectedStatusorder', status);
  };

  const gridItemAction = (itemId) => (
	<div className="flex items-center justify-center gap-2">
	<h1
	  style={{ color: "#03c9d7", display: "flex" }}
	  className="font-small leading-tight text-xl mt-0 mb-2 "
	>
	  {/* <Link
		style={{ marginRight: "auto" }}
		to={`/employeewiseorders/view/${itemId._id}`}
	  >
		<FiEye />
	  </Link> */}
	 <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/orders/view"
      >
        <FiEye onClick={()=>setOrderData(itemId)}/>
      </Link>
	  <h1
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5, marginLeft: "10px" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
        onClick={() => {
          setOrderId(itemId._id);
        }}
      >
        <DeleteModal />
      </h1>
	</h1>
  
  
	<button
	  style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
	  className="font-small leading-tight text-xl mt-0 mb-2 "
	>
  {/* <OrderDelete itemId={itemId._id} /> */}
	</button>
  </div>
  );

  
const gridConfirm = (props) => (
	<div class="flex space-x-2">
	  <h6
		style={{ color: "#03c9d7", display: "flex" }}
		class="font-medium leading-tight text-3xl mt-0 mb-2 "
	  >
	   
		{props.orderStatus == "PENDING" && <ConfirmModal props={props} />}
		{props.orderStatus == "PROCESSING" && (
		  <div>
			{/* <Link to={`/orders/assign/${props._id}`}>
			  <button
				style={{ color: "white", backgroundColor: "#8be78b" }}
				type="button"
				class="px-2
		py-2
		bg-blue-600
		text-white
		font-small
		text-xs
		leading-tight
		uppercase
		rounded
		shadow-md
		hover:bg-blue-700 hover:shadow-lg
		focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
		active:bg-blue-800 active:shadow-lg
		transition
		duration-150
		ease-in-out"
			  >
				Assign
			  </button>
			</Link> */}
			{/* <BsFillPencilFill onClick={() => itemEdit(itemId)} /> */}
			<button
				style={{ color: "white", backgroundColor: "#8be78b" }}
				type="button"
				class="px-2
		py-2
		bg-blue-600
		text-white
		font-small
		text-xs
		leading-tight
		uppercase
		rounded
		shadow-md
		hover:bg-blue-700 hover:shadow-lg
		focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
		active:bg-blue-800 active:shadow-lg
		transition
		duration-150
		ease-in-out"
		id="OrderBnt"
			  >
				<AssignPopup id={props}/>
			   
			  </button>
		  </div>
		)}
		{props.orderStatus == "PACKAGING" && (
		  <div>
			<button
			  style={{ color: "white", backgroundColor: "#8be78b" }}
			  type="button"
			  class="px-2
		py-2
		bg-blue-600
		text-white
		font-small
		text-xs
		leading-tight
		uppercase
		rounded
		shadow-md
		hover:bg-blue-700 hover:shadow-lg
		focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
		active:bg-blue-800 active:shadow-lg
		transition
		duration-150
		ease-in-out"
			>
			   <AssignedPopup props={props}/>
			</button>
		  </div>
		)}
		{props.orderStatus == "SHIPPING" && (
		  <div>
			<button
			  style={{ color: "white", backgroundColor: "#8be78b" }}
			  type="button"
			  class="px-2
		py-2
		bg-blue-600
		text-white
		font-small
		text-xs
		leading-tight
		uppercase
		rounded
		shadow-md
		hover:bg-blue-700 hover:shadow-lg
		focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
		active:bg-blue-800 active:shadow-lg
		transition
		duration-150
		ease-in-out"
			>
			   <ShipPopup props={props}/>
			</button>
		  </div>
		)}
   
	  </h6>
  
	</div>
  );

  console.log(orderData, "adf")

  const orderFilterData = Array.isArray(orderData)
  ? orderData.filter(item => 
      item?.orderNo?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.userId?.firstName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.pincode?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  : [];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0); // Reset to the first page whenever a new search is made
  };

  const paginatedData = orderFilterData.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	  );

  return (
    <div class="tableflex">
      <div className="m-6 md:m-10 mt-24 p-6 md:px-10 bg-white rounded-3xl">
        {/* <h3
          style={{ color: "#B2A59B" }}
          class="font-medium leading-tight text-3xl mt-0 mb-2 "
        >
          Total Order Amount :{" "}
          <span style={{ color: "#607274" }}> {totalAmount}</span> & Total
          Commission :<span style={{ color: "#607274" }}> {totalCommission} </span>
        </h3> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 0 20px 0",
            padding: "8px",
            borderRadius: "20px",
            background: "rgb(0, 60, 126)",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
            Order List ({length})
          </h1>
          <span
            className="flex space-x-2 m-4 justify-end"
            style={{ background: "white", borderRadius: "6px" }}
          >
            <input
              style={{
                padding: "4px 10px",
                outline: "none",
                borderRadius: "6px 0 0 6px",
                color: "black",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <BiSearch
              style={{
                fontSize: "25px",
                color: "gray",
                cursor: "pointer",
                margin: "auto",
                paddingRight: "5px",
              }}
            />{" "}
          </span>
        </div>

        <TableContainer
          className={orderData?.length > 0 ? "table-container" : ""}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellRow}>Order No</TableCell>
                <TableCell className={classes.cellRow}>Date</TableCell>
                <TableCell className={classes.cellRow}>Client</TableCell>
                <TableCell className={classes.cellRow}>Distributor</TableCell>
                <TableCell className={classes.cellRow}>Amount</TableCell>
                <TableCell className={classes.cellRow}>Order Status</TableCell>
                <TableCell className={classes.cellRow}>Payment Mode</TableCell>
                <TableCell className={classes.cellRow}>
                  Payment Status
                </TableCell>
                <TableCell className={classes.cellRow}>Order Actions</TableCell>
                <TableCell className={classes.cellRow}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData?.length > 0 ? (
                paginatedData.map((item, i) => (
                  <TableRow key={item._id}>
                    <TableCell className={classes.cell}>
                      {item.orderNo}
                    </TableCell>
                    <TableCell className={classes.cell}>{formatDate(item?.date)}</TableCell>
                    <TableCell className={classes.cell}>
                      {item?.userId?.firstName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.distributorId?.firmName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.totalPrice}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.orderStatus}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.paymentMode}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.paymentStatus}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {gridConfirm(item)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {gridItemAction(item)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {paginatedData?.length > 0 ? (
          <TablePagination
            count={orderData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[2]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
        </TableContainer>

        {/* <GridComponent
          id="gridcomp"
          width="auto"
          // dataSource={itemsData}
          dataSource={orderData}
          allowPaging
          allowSorting
          allowExcelExport
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
        >
          <ColumnsDirective>
            {itemsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Search,
              ContextMenu,
              Page,
              Selection,
              Toolbar,
              Edit,
              Sort,
              Filter,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent> */}
      </div>
    </div>
  );
};
export default Orders;


// import React,{useState,useEffect,useContext} from "react";
// import { Header } from "../../components";
// import StateContext from "../../contexts/ContextProvider";
// import axios from "axios";
// import { green } from "@mui/material/colors";
// import { Link } from "react-router-dom";
// import { BsFillPencilFill } from "react-icons/bs";
// import { AiFillDelete } from "react-icons/ai";
// import { FiEye } from "react-icons/fi";
// import {
// 	FirstPageOutlined,
// 	LastPageOutlined,
// 	NavigateBefore,
// 	NavigateNext,
//   } from "@material-ui/icons";
// import {
//   AppBar,
//   Divider,
//   Fade,
//   Grid,
//   Modal,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   Backdrop,
//   makeStyles,
// } from "@material-ui/core";
// import { BiSearch } from "react-icons/bi";
// import { Button, CircularProgress,  Toolbar, Typography } from "@mui/material";
// const useStyles = makeStyles((theme) => ({
// 	table: {
// 	  minWidth: 650,
// 	  borderCollapse: "collapse",
// 	},
// 	cell: {
// 	  padding: "8px",
// 	  textAlign: "center",
// 	  // border: "1px solid " + theme.palette.divider,
// 	},
// 	cellRow: {
// 	  // border: "1px solid " + theme.palette.divider,
// 	  padding: "15px",
// 	  textAlign: "center",
// 	},
//   }));

// const Orders = () => {

// const {orders,setOrders}=useContext(StateContext)
// const dataArray = []
// const [tDatas, setTDatas] = useState([]);
//  const {setOrderData,orderData}=useContext(StateContext)
// const { setViewData,setValue,value } = useContext(StateContext);
// const [searchQuery, setSearchQuery] = useState("");
// const classes = useStyles();
// const [loading, setLoading] = useState(false);
// const [currentPage, setCurrentPage] = useState(0); // Current page number
// const [pageSize, setPageSize] = useState(10);
// const [length, setLength] = useState(0)

// const contextMenuItems = [
//   "AutoFit",
//   "AutoFitAll",
//   "SortAscending",
//   "SortDescending",
//   "Copy",
//   "Edit",
//   "Delete",
//   "Save",
//   "Cancel",
//   "PdfExport",
//   "ExcelExport",
//   "CsvExport",
//   "FirstPage",
//   "PrevPage",
//   "LastPage",
//   "NextPage",
// ];

// // useEffect(async()=>{
// //   const order=await axios.get("https://api.orbitmart.co.in/api/v1/order").then((res)=>{
// //      let data=res.data.data.map((or)=>{ or.grandTotal=getAmount(or);return or})
// //      setTDatas(data)
   
// //   })},[])

// const handleOrderFun = async() => {
//   try{
//     const response = await axios.get("https://api.orbitmart.co.in/api/v1/order");
//     setTDatas(response.data.data)
//     setLength(response.data.data.length)
//   }catch(err){
//     console.log(err,"err")
//   }
// }

// useEffect(()=>handleOrderFun() ,[])

//   console.log(tDatas, "tDatas")

//     const getAmount=(or)=>{
//      return ((or.totalPrice)-(or.productDetails.map((pr)=>{

//       if(pr?.productId?.discountType=='FIXED')
//       {
//         return  parseInt(pr.productId.customerDiscount)
//       }
//       else{
//         return  (parseInt(pr.productId?.itemPrice)*parseInt(pr.productId?.customerDiscount!==""?pr.productId?.customerDiscount:0))/100
//       }
//     }).reduce((a,b)=>a+b))+ (((or.totalPrice*parseInt(or.productDetails[0].productId?.gst))/100))+20).toFixed(0)
//     }
   
// const gridPaymentMode= (props) => (
//   <b><h6
//    style={{ color:fetchModeColor(props.paymentMode)}}
//   className=" py-1 px-2 capitalize rounded-2xl text-md "
// >
//   {props.paymentMode}
// </h6></b> 
// )


//   const gridPaymentStatus=(props)=>(
//     <div
//      style={{ color:fetchPaymentColor(props.paymentStatus)}}
//     className=" py-1 px-2 capitalize rounded-2xl text-md"
//   >
//     <b>{props.paymentStatus}</b>
//   </div>
//   )
// const gridOrdersStatus = (props) => (
//   <button
//     type="button"
//      style={{ background:fetchColor(props.orderStatus)}}
//     className="text-white py-1 px-2 capitalize rounded-2xl text-md"
//   >
//     {props.orderStatus}
//   </button>
// );
// const fetchModeColor=(status)=>{
//   switch(status){
//     case 'NOT SELECTED':
//       return "#ff1a74"
//     case 'COD':
//       return "green"
//     default:
//       return "#0b5e70"

//   }
// }

// const fetchPaymentColor=(status)=>{
//   switch(status){
//     case 'PENDING':
//       return "blue"
//     default:
//       return "green"
// }}
// const fetchColor=(status)=>{
//   switch(status){
//     case 'PENDING':
//       return "#ff1a74"
//     case 'PROCESSING':
//       return "green"
//     case 'REJECTED':
//       return "#b30000"
//     case 'SHIPPING':
//       return "#0b5e70"
//     default:
//       return "blue"

//   }
// }

// const gridOrderEdit = (or) => (
//   <div className="flex items-center justify-center gap-2">
//     <h1
//       style={{ color: "#8be78b", display: "flex" }}
//       className="font-small leading-tight text-xl mt-0 mb-2 "
//     >
//       <Link style={{ marginRight: "auto" }} to="/orders/edit">
//         <BsFillPencilFill onClick={()=>setOrderData(or)} />
//       </Link>
//     </h1>
//     <h1
//       style={{ color: "#03c9d7", display: "flex" }}
//       className="font-small leading-tight text-xl mt-0 mb-2 "
//     >
//       <Link
//         style={{ marginLeft: "auto", marginRight: "auto" }}
//         to="/orders/view"
//       >
//         <FiEye onClick={()=>setOrderData(or)}/>
//       </Link>
//     </h1>
//     <h1
//       style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
//       className="font-small leading-tight text-xl mt-0 mb-2 "
//     >
//       {/* <DeleteModal /> */}
//     </h1>
//   </div>
// );

// // const getAmount=(props)=>(
// //   <div>{tDatas.filter((or)=>or._id==props.id).map((or)=>{
// //    return ((or.totalPrice)+ (((or.totalPrice*parseInt(or.productDetails[0].productId.gst))/100))+20).toFixed(0)
// //   })}
// //     </div>

// // )
// const gridOrderNo=(props)=>(
//   <h6 className="text-uppercase">{props.orderNo}</h6>
// )
//   const selectionsettings = { persistSelection: true };
//   const toolbarOptions = ["Search"];

//   const editing = { allowDeleting: true };


  
// 	const handleChangePage = (event, newPage) => {
// 		setCurrentPage(newPage);
// 	  };
// 	  const handleChangeRowsPerPage = (event) => {
// 		setPageSize(parseInt(event.target.value, 10));
// 		setCurrentPage(0);
// 	  };
// 	  const changeToFirstPage = () => {
// 		setLoading(true);
// 		setCurrentPage(0);
// 		setTimeout(() => {
// 		  setLoading(false);
// 		}, 500);
// 	  };
	
// 	  const changeToPrevPage = () => {
// 		setLoading(true);
// 		setCurrentPage(currentPage - 1);
// 		setTimeout(() => {
// 		  setLoading(false);
// 		}, 500);
// 	  };
	
// 	  const changeToNextPage = () => {
// 		setLoading(true);
// 		setCurrentPage(currentPage + 1);
// 		setTimeout(() => {
// 		  setLoading(false);
// 		}, 500);
// 	  };
	
// 	  const changeToLastPage = () => {
// 		setLoading(true);
// 		setCurrentPage(Math.ceil(length / pageSize) - 1);
// 		setTimeout(() => {
// 		  setLoading(false);
// 		}, 500);
// 	  };

//   return (
// 		<div className="m-6 md:m-10 mt-24 p-6 md:p-10 bg-white rounded-3xl">
// 		  <div className="flex space-x-2 m-4 justify-end">
// 			{/* <button type="button" onClick={() => addPage()} className="addBtn">
// 			  New Record
// 			</button> */}
// 		  </div>
// 		  {console.log(tDatas.data, "tDatas.data")}
// 		  <AppBar
// 			position="static"
// 			style={{ backgroundColor: "#003c7e", borderRadius: "20px" }}
// 		  >
// 			<Toolbar style={{ justifyContent: "space-between" }}>
// 			  <Typography variant="h6">Orders (0)</Typography>
// 			  <span
// 				className="flex space-x-2 justify-end"
// 				style={{ background: "white", borderRadius: "6px" }}
// 			  >
// 				<input
// 				  style={{
// 					padding: "4px 10px",
// 					outline: "none",
// 					borderRadius: "6px 0 0 6px",
// 					color: "black",
// 				  }}
// 				  type="text"
// 				  placeholder="Search..."
// 				  value={searchQuery}
// 				  onChange={(e) => setSearchQuery(e.target.value)}
// 				/>
// 				<BiSearch
// 				  style={{
// 					fontSize: "25px",
// 					color: "gray",
// 					cursor: "pointer",
// 					margin: "auto",
// 					paddingRight: "5px",
// 				  }}
// 				/>{" "}
// 				{/*  this is the search icon */}
// 			  </span>
// 			</Toolbar>
// 		  </AppBar>
// 		  {/* {console.log({ filteredData })} */}
// 		  <div style={{ marginTop: "10px" }}></div>
// 		  <Divider />
// 		  <TableContainer>
// 			<Table>
// 			  <TableHead>
// 				<TableRow>
// 				  <TableCell className={classes.cellRow}>Order No.</TableCell>
// 				  <TableCell className={classes.cellRow}>Order</TableCell>
// 				  <TableCell className={classes.cellRow}>Amount</TableCell>
// 				  <TableCell className={classes.cellRow}>Payment Mode</TableCell>
// 				  <TableCell className={classes.cellRow}>Payment Status</TableCell>
// 				  <TableCell className={classes.cellRow}>Order Status</TableCell>
// 				  {/* {headerValue == "" ? null : <TableCell className={classes.cellRow}>{headerValue}</TableCell>} */}
// 				  <TableCell className={classes.cellRow}>Action</TableCell>
// 				</TableRow>
// 			  </TableHead>
// 			  <TableBody>
// 				{
// 				  tDatas.map((item) => (
// 					<TableRow key={item._id}>
// 					  <TableCell
// 						className={classes.cell}>{item.orderNo}</TableCell>
// 					  <TableCell className={classes.cell}>{item?.productDetails?.productId?.itemName}</TableCell>
// 					  <TableCell className={classes.cell}>{item.totalPrice}</TableCell>
// 					  <TableCell className={classes.cell}>{item.paymentMode}</TableCell>
// 					  <TableCell className={classes.cell}>{item.paymentStatus}</TableCell>
// 					  <TableCell className={classes.cell}>{item.orderStatus}</TableCell>
// 					  <TableCell className={classes.cell}>
// 						{gridOrderEdit(item)}
// 					  </TableCell>
// 					</TableRow>
// 				  ))}
// 				  <TableRow>
// 					<TableCell colSpan={7} align="center">
// 					  No Data Available
// 					</TableCell>
// 				  </TableRow>
// 			  </TableBody>
// 			</Table>
// 			{ tDatas ? (
// 			  <TablePagination
// 				count={length}
// 				component="div"
// 				page={currentPage}
// 				rowsPerPage={pageSize}
// 				onPageChange={handleChangePage}
// 				onRowsPerPageChange={handleChangeRowsPerPage}
// 				rowsPerPageOptions={[10]}
// 				ActionsComponent={() => (
// 				  <div style={{ display: "flex", alignItems: "center" }}>
// 					<Button
// 					  onClick={changeToFirstPage}
// 					  disabled={currentPage === 0}
// 					>
// 					  <FirstPageOutlined />
// 					</Button>
// 					<Button onClick={changeToPrevPage} disabled={currentPage === 0}>
// 					  <NavigateBefore />
// 					</Button>
// 					<span className="myPage">{currentPage + 1}</span>
// 					<Button
// 					  onClick={changeToNextPage}
// 					  disabled={
// 						currentPage >= Math.ceil(tDatas / pageSize) - 1
// 					  }
// 					>
// 					  <NavigateNext />
// 					</Button>
// 					<Button
// 					  onClick={changeToLastPage}
// 					  disabled={
// 						currentPage >= Math.ceil(length / pageSize) - 1
// 					  }
// 					>
// 					  <LastPageOutlined />
// 					</Button>
// 				  </div>
// 				)}
// 			  />
// 			) : null}
// 		  </TableContainer>
// 		</div>	
	
// 	  );


// //   return (
// //     <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
// //       <Header title="Orders" />
// //       {/* <Header category="Page" title="Orders" /> */}
// //       <GridComponent dataSource={tDatas} allowPaging={true} pageSettings={{pageSize:10}}       

// // width="auto"

// // allowSorting
// // allowExcelExport
// // allowPdfExport

// // contextMenuItems={contextMenuItems}
// // selectionSettings={selectionsettings}
// // editSettings={editing}
// // toolbar={toolbarOptions}
// // allowFiltering={true}
// // filterSettings={{ ignoreAccent: true, type: "Excel" }} 
// // >
// //         <ColumnsDirective>
// //         <ColumnDirective field="_id" headerText="ID" textAlign="center"  isPrimaryKey={true} visible={false} />
// //         <ColumnDirective field="orderNo" headerText="Order No" textAlign="center" width="100" template={gridOrderNo}  />
// //    <ColumnDirective field="createdAt" headerText="Order On" textAlign="center" width="60" type='date' format="E,MMM dd yyyy,hh:mm a"    />
// //            {/*   format='E,MMM dd yyyy,hh:mm a' */}
// //         <ColumnDirective field="grandTotal" headerText= "Amount" textAlign= "Center" width="60" />
// //         <ColumnDirective field= "paymentMode" headerText= "Payment Mode" textAlign= "Center" width="100" template={gridPaymentMode}/>
// //         <ColumnDirective field="paymentStatus" headerText="Payment Status" textAlign="center" width="90" template={gridPaymentStatus} />
// //         <ColumnDirective field="orderStatus" headerText="Order Status" textAlign="center" width="90"  template={gridOrdersStatus} />
// //         <ColumnDirective field= "_id"  headerText= "Action" textAlign= "Center" width="100"  template={gridOrderEdit} allowFiltering={false} />

// //             </ColumnsDirective>
// //             <Inject
// //           services={[
// //             Resize,
// //             Search,
// //             ContextMenu,
// //             Page,
// //             Selection,
// //             Toolbar,
// //             Edit,
// //             Sort,
// //             Filter,
// //             ExcelExport,
// //             PdfExport,
// //           ]}
// //         />
// //       </GridComponent>
      
// //     </div>
// //   );
// };
// export default Orders;
