import React,{useContext,useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "./vieworder.css"
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";

const ViewOrder = () => {
  const {setOrderData,orderData}=useContext(StateContext)
  const [viewOrderData, setViewOrderData] = useState([])
  
  const staticValue = 0
  const orbitMartCommissionLogic = staticValue;
  let subtotal = staticValue
  const customerDiscount = viewOrderData.totalDiscount
  const WrongGst = staticValue
  const GstLogic = viewOrderData.totalGST
  const DeliveryAmount = viewOrderData.totalDeliveryCharge
  const TotalAmount = viewOrderData.totalPrice;
  const payMerchant = staticValue

  const HandleViewFunc = async () => {
    const id = orderData._id;
    try{
      const response = await axios.get(`https://api.orbitmart.co.in/api/v1/order/${id}`)
      setViewOrderData(response.data.data)
    }catch(err) {
      console.error("error in viewOrder page something is missing", err)
    }
  };
  useEffect(()=> HandleViewFunc(),[])

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl ">
      <div class="fixbox ">
        <div class="box" style={{background: '#003c7e', border: '1px solid #003c7e'}}>
          <div class="flex justify-end active">
            <div class="backIcon">
              <Link to="/orders" >
                <AiOutlineRollback  />
              </Link>
            </div>
          </div>
          <div class="flex-container  tablefont1">
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                {/* {viewOrderData.distributorId.firstName} */}
                {/* here what data you want? */}
               Client Name: {viewOrderData.userId?.firstName}
                {/* {viewOrderData.firstName} */}
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {/* {viewOrderData.data.distributorId.email} */}
                {viewOrderData.userId?.email}
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {/* {viewOrderData.data.distributorId.mobile} */}
                {viewOrderData.userId?.mobile}
              </h6>
            </div>

            {/* <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                KUDAL
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                sitaramteli83@gmail.com
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                7218650239
              </h6>
            </div> */}
          </div>
          <hr class="new1"></hr>
          <div className="flex-container  tablefont1">
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER DATE
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {new Date(viewOrderData?.createdAt).toLocaleString("en-US", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER NO.
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {viewOrderData?.orderNo}
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER STATUS
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {viewOrderData?.orderStatus}
              </h6>
            </div>
            <div>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 ">
                ORDER TO
              </h6>
              <h6 class="font-small leading-tight text-base mt-0 mb-2 ">
                {/* madhura arcade, ground floor C Wing, Ground Floor, Mathura Arcade, Gudhipur, Kudal, Maharashtra 416520, India */}
                {viewOrderData?.address}
              </h6>
            </div>
          </div>
        </div>
        <div class="tablefont1">
          <table class="min-w-full auto">
            <thead class="bg-white border-b">
              <tr>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900  text-left"
                >
                  MERCHANT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CATEGORY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  CLASS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  SUB CLASS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  ITEMS
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  UNIT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  QTY
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-2 py-2 text-left"
                >
                  AMOUNT
                </th>
                <th
                  scope="col"
                  class="text-sm font-medium text-gray-900 px-4 py-2 text-right "
                >
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              {viewOrderData?.productDetails?.map((pro) => {
                subtotal = pro?.productId?.itemPrice * pro?.quantity||0
              return (
                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {viewOrderData?.merchantId?.firmName}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {pro?.productId?.categoryId?.title}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  {pro?.productId?.classificationId?.title}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  {pro?.productId?.subClassificationId?.title}
                  </td>

                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  {pro?.productId?.itemName}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {/* {viewOrderData?.productId?.unitQuantityId?.title} */}
                    {pro?.productId?.unitQuantityId?.title}
                  </td>

                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {/* {viewOrderData.productDetails[0].productId.stockQuantity} */}

                    {pro?.quantity}
                  </td>
                  <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    {/* {viewOrderData.totalPrice} */}
                    {pro?.productId?.itemPrice}
                  </td>
                  <td class="px-6  py-4 whitespace-nowrap text-md font-medium text-green-900 text-right">
                  ₹{(pro?.productId?.itemPrice * pro?.quantity)||0}
                  </td>
                </tr>
              )})}

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  SUB TOTAL
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                ₹{subtotal}
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  DISCOUNT AMOUNT
                  <span className=" py-4 whitespace-nowrap text-md font-small text-red-500 text-right">(-)</span>
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-red-500 text-right">
                ₹{customerDiscount}
                </td>
              </tr>
              {/* <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  ORBIT MART COMMISSION
                </td>
                <td class=" ViewPrice px-6  py-4 whitespace-nowrap text-md font-medium text-green-900">
       
                  {orbitMartCommissionLogic}
                </td>
              </tr> */}

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100 ">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  GST
                </td>
                {/* ViewPrice */}
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                ₹{GstLogic}
                </td>
              </tr>
              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  DELIVERY AMOUNT
                </td>
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                ₹{DeliveryAmount}
                </td>
              </tr>

              <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="text-sm text-gray-900  font-light px-2 py-2 whitespace-nowrap"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>

                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                <td class="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                  FINAL AMOUNT
                  {/* <span className="finailAmoutnSign">(+)</span> */}
                  <span className=" py-4 whitespace-nowrap text-md font-small text-blue-500 text-right">(+)</span>
                </td>
            
                <td class="  px-6  py-4 whitespace-nowrap text-md font-small text-green-900 text-right">
                ₹{parseInt(TotalAmount).toFixed(0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footerfont">
          <div class="flex-container  tablefont1">
            {viewOrderData?.orderStatus !== "PENDING" &&
            viewOrderData?.orderStatus !== "PROCESSING" &&viewOrderData?.orderStatus !== "REJECTED" ? (
              <div className="container_Assign ">
            
                <div className="Assign_hedding w-75">
                  <h3 className="Assign_hedding1">
                    Assign Employee Details
                    <span className="Assign_hedding2">*</span>
                  </h3>
                </div>
                <div className="AssignDtls">
                  <div>
                    <h5 className="DtlsName">
                      Order Pick Date and Time:-
                      <span className="asign1">
                        {viewOrderData?.distributorId?.createdAt}
                        {/* {viewOrderData.createdAt} */}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      {"Order Delivery Date and Time:-    "}
                      <span className="asign2">
                      {viewOrderData?.distributorId?.updatedAt}
                      {/* {`${viewOrderData.deliveryDate}, ${viewOrderData.deliveryTime} AM`} */}
                      </span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Name:- 
                      <span className="asign4 ">{viewOrderData?.employeeId?.firstName}</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Email:-
                      <span className="asign4">{viewOrderData?.employeeId?.email}</span>
                    </h5>
                  </div>
                  <div>
                    <h5 className="DtlsName">
                      Employee Mobile:-
                      <span className="asign5">{viewOrderData?.employeeId?.mobile}</span>
                    </h5>
                  </div>
                </div>
              </div>
              
            ) : (
              ""
            )}
            {/* <div class="right">
              <h4 class="font-medium leading-tight text-2xl mt-0 mb-2 text-blue-600">
                Total Amount
              </h4>
              <h3 class="font-large leading-tight text-3xl mt-0 mb-2 text-green-800">
                <span class="text-xxl inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold bg-blue-600 text-white rounded-full">
                ₹{parseInt(TotalAmount).toFixed(0)}
                </span>
              </h3>
              <h6 class="font-medium leading-tight text-base mt-0 mb-2 text-blue-600">
                Taxes Included
              </h6>
              {viewOrderData?.orderStatus !== "PENDING" &&
            viewOrderData?.orderStatus !== "PROCESSING" &&viewOrderData?.orderStatus !== "REJECTED" ?(<div className="tbody1">Pay amount to Merchant:<h6>₹{payMerchant}</h6></div>):""}
            </div> */}
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );

  // return (
  //   <div class="container">
  //     <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  //       <div class="flex justify-end active">
  //         <div class="backIcon">
  //           <Link to="/orders">
  //             <AiOutlineRollback />
  //           </Link>
  //         </div>
  //       </div>
  //       <div class="flex justify-center">
  //         <ul class="bg-white rounded-lg w-96 text-gray-900">
  //         <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
  //             Order No :  {viewOrderData.orderNo}
  //           </li>
         
  //           <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
  //             Client Name :  {viewOrderData?.userId?.firstName}
  //           </li>
  //           <li class="px-6 py-2 border-b border-gray-200 w-full">
  //             Date : {new Date(viewOrderData.createdAt).toLocaleString("en-US", {
  //           weekday: "short",
  //           day: "2-digit",
  //           month: "short",
  //           year: "numeric",
  //           hour: "2-digit",
  //           minute: "2-digit",
  //         })}
  //           </li>
  //           <li class="px-6 py-2 border-b border-gray-200 w-full">
  //             Amount : {viewOrderData.grandTotal}
  //           </li>
  //           <li class="px-6 py-2 border-b border-gray-200 w-full">
  //             Order Staus:{viewOrderData.orderStatus}
  //           </li>
  //           <li class="px-6 py-2 border-b border-gray-200 w-full">
  //             Payment Mode :{viewOrderData.paymentMode}
  //           </li>
  //           <li class="px-6 py-2 w-full rounded-b-lg">Payment Status : {viewOrderData.paymentStatus} </li>
  //         </ul>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default ViewOrder;
