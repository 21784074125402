import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState, useContext } from "react";
import axios from "../api/axiosapi";
import StateContext from "../../contexts/ContextProvider";
import { Navigate } from "react-router-dom";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const EmployeeToggle = (props) => {
  const [state, setState] = useState(props.adminActiveInactive);
  const [empInfo, setEmpInfo] = useState(props.emp);
  const {setEmployees}=useContext(StateContext)
  const token = sessionStorage.getItem("token")
  
  const handleChange = async (e) => { 
 
    const header = {"x-token": token}
    const updated=await axios
      .put(
        `https://api.orbitmart.co.in/api/v1/auth/admin/setstatus/employee/${empInfo._id}`,{},
       { headers: header
       })
      .then((res) => res.data);
    if(updated.status="SUCCESS"){
        setState(!state)
        await axios.get("https://api.orbitmart.co.in/api/v1/auth/allEmployee").then((res)=>{setEmployees(res.data.data);
    })
  }
  e.preventDefault();
}

  
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch sx={{ m: 1 }} checked={state} onChange={handleChange} />
        }
      />
    </FormGroup>
  );
};

export default EmployeeToggle;
