import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import axios from "../api/axiosapi";
import StateContext from "../../contexts/ContextProvider";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";
import { Alert, Stack } from "@mui/material";

const AddBanner = () => {
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token")
  const { setValue } = useContext(StateContext);

  const finalToken = token.token;
  console.log(token);

  console.log(finalToken);

  const mainPage = () => {
    navigate("/banner");
  };

  const [bannerName, setBannerName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [pincode, setPincode] = useState("");

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  // const initialState = {
  //   bannerName: "",
  //   locationName: "",
  //   pincode: "",
  // };

  // const [addData, setAddData] = useState(initialState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setAddData({ ...addData, [name]: value });
  //   console.log(addData);
  // };
  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  console.log(selectedImages, "selectedImages")

  function postData() {

    if(bannerName==""||locationName==""||pincode=="")
    {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1000);

    }
    else{

  

    const uploadAPI = "https://api.orbitmart.co.in/api/v1/upload";
    const uploadData = "https://api.orbitmart.co.in/api/v1/auth/admin/banner/add";
    const formData = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("Image", selectedImages[i]);
    }
    // formData.append("Image", image);
    axios.post(uploadAPI, formData).then((res) => {
      console.log(res);
      var newimg = res.data.files;
      var coverPhoto = newimg[0];
      debugger;

  
      console.log(coverPhoto);
      console.log(bannerName);
      console.log(locationName);
      console.log(pincode);
      // var newData = { ...coverPhotos, ...addData };
      // console.log(newData);
      debugger;
      var finalData = {
        bannerName: bannerName,
        locationName: locationName,
        pincode: pincode,
        coverPhoto: coverPhoto,
      };
      console.log(finalData);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-token": token },
        body: JSON.stringify(finalData),
      };
      fetch(uploadData, requestOptions)
        .then(async (response) => {
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          const data = isJson && (await response.json());
          console.log(data);
          setAlert(true);
          setTimeout(() => {
            mainPage();
          }, 1000);
          // check for error response
          if (!response.ok) {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
        })
        .catch((error) => {
          setError(true);
          setTimeout(() => {
            mainPage();
          }, 1000);
          console.log(error.message);
          console.error("There was an error!", error);
        });
    });
  }
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Data Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Data Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-3xl mt-0 mb-2 text-blue-600">
        Add Banner
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="bannerName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Banner Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="bannerName"
              onChange={(e) => setBannerName(e.target.value)}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="locationName"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Location Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="locationName"
              onChange={(e) => setLocationName(e.target.value)}
            />
          </div>

          <div
            class="relative z-0 p-2 w-full mb-6 group"
            style={{ marginTop: 8 }}
          >
            <label>Pin Code</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="pincode"
              onChange={(e) => setPincode(e.target.value)}
            >
              <option selected>----Select----</option>
              <option value="Alllocation">All location</option>
              <option value="400182">400182</option>
              <option value="723416">723416</option>
              <option value="564837">564837</option>
              <option value="342213">342213</option>
              <option value="700278">700278</option>
            </select>
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700">
              Cover photo
            </label>
            <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              {/* <div class="space-y-1 text-center"> */}
              <div class="flex text-sm text-gray-600">
                <label
                  for="file-upload"
                  class="relative top-0 right-0 cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              {/* </div> */}
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div>
            <label class="block text-sm font-medium text-gray-700">
              Cover photo
            </label>
            <div>
             
              <div>
                <label
                  for="file-upload"
                  class="relative top-0 right-0 cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
        <div class="flex space-x-2 justify-end mr-2">
          <button
            type="button"
            style={{ width: "8%" }}
            onClick={postData}
            class="addButton"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBanner;
