import React, { useEffect, useState, useContext } from 'react';
import { BsFillPencilFill } from 'react-icons/bs';
import { contextMenuItems, ordersGrid, ordersData } from './config/BannerConfig';
import { Header } from '../../components';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../css/view.css';
import axios from '../api/axiosapi';
// import StateContext from '../../contexts/ContextProvider';
// import DeleteBannerModel from './DeleteBannerModel';
import {
	AppBar,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	makeStyles,
  } from "@material-ui/core";
  import { BiSearch } from "react-icons/bi";
  import { AiFillDelete } from "react-icons/ai";
  import { FiEye } from "react-icons/fi";
//   import { useNavigate } from "react-router-dom";
//   import { Link } from "react-router-dom";
  import "../view.css";
//   import axios from "axios";
  import StateContext from "../../contexts/ContextProvider";
  import DeleteBannerModel from "./DeleteBannerModel";
  import { Button } from "@material-ui/core";
  import {
	FirstPageOutlined,
	LastPageOutlined,
	NavigateBefore,
	NavigateNext,
  } from "@material-ui/icons";
  
  
  
  
  const useStyles = makeStyles((theme) => ({
	table: {
	  minWidth: 650,
	  borderCollapse: "collapse", 
	},
	cell: {
	  padding: "8px 0px 8px 16px", 
	  textAlign: "start",
	  // border: "1px solid " + theme.palette.divider,
	},
	cellRow: {
	  // border: "1px solid " + theme.palette.divider,
	  padding: "15px", 
	  textAlign: "start",
	},
  }));

//Toaster
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

const Banner = () => {
	const [ tDatas, setTDatas ] = useState([]);
	const classes = useStyles();
    const token = sessionStorage.getItem("token")
	const { value } = useContext(StateContext);
	const { setValue, setBanner } = useContext(StateContext);
	const [record, setRecord] = useState(false);
	const [currentPage, setCurrentPage] = useState(0); 
	const [pageSize, setPageSize] = useState(10);
	const [loading, setLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	const selectionsettings = { persistSelection: true };
	const toolbarOptions = [ 'Search' ];

	const editing = { allowDeleting: true, allowEditing: true };

	const random = 193;
	const header = {
		"x-token": token
	}

	// const fetchData = () => {
	// 	fetch(`https://api.orbitmart.co.in/api/v1/auth/admin/banner/all`,{
    //      headers: header
	// 	})
	// 		.then((response) => {
	// 			return response.json();
	// 		})
	// 		.then((data) => {
	// 			setTDatas(data);
	// 			if (data.data.length == 8) {
	// 				setRecord(true);
	// 			  }
	// 		});
	// };
	const fetchData = async () => {
		try {
		  const response = await axios.get('https://api.orbitmart.co.in/api/v1/auth/admin/banner/all', {
			headers: header
		  });
		  
		  setTDatas(response.data.data.data);
		  console.log(response.data.data.data, "gridImage")
		//   if (response.data.data.data.length === 8) {
		// 	setRecord(true);
		//   }
		} catch (error) {
		  // Handle error
		  console.error('Error fetching data:', error);
		}
	  };

	useEffect(() => {
		fetchData();
	}, []);

	if (value == 201) {
		fetchData();
		setValue(random);
	}

	const navigate = useNavigate();
	const addPage = () => {
		navigate('/banner/add');
	};
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setCurrentPage(0); // Reset to the first page whenever a new search is made
	  };

	function gridAction(itemId) {
		return (
			<div className="flex items-center gap-2">
				<h1
					style={{ color: '#8be78b', display: 'flex' }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<Link style={{ marginRight: 'auto' }} to={`/banner/edit/${itemId._id}`}>
						<BsFillPencilFill onClick={() => setBanner(itemId)} />
					</Link>
				</h1>
				<button
					style={{ color: '#ff5c8e', display: 'flex', marginTop: 2.5 }}
					className="font-small leading-tight text-xl mt-0 mb-2 ">
					<DeleteBannerModel itemId={itemId._id} />
				</button>
			</div>
		);
	}

	function gridImage(itemId) {
		return <img className=" h-20 md:ml-3" style={{padding: '5px 0', marginLeft: '0', borderRadius: '5px'}} src={itemId.coverPhoto[0]} alt="order-item" />;
	}

	// const filteredData = tDatas.filter(item => item?.bannerName.toLowerCase().includes(searchQuery.toLowerCase()));
	const filteredData = tDatas.filter(item => 
		item.bannerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
		item.locationName.toLowerCase().includes(searchQuery.toLowerCase())
	  );
	console.log(filteredData, "filteredData filteredData")
  
	const handleChangePage = (event, newPage) => {
	  if (newPage === 0) {
	   setCurrentPage(0);
	 } else if (newPage === Math.ceil(tDatas?.length / pageSize) - 1) {
	   setCurrentPage(Math.ceil(tDatas?.length / pageSize) - 1);
	 } else {
	   setCurrentPage(newPage);
	 }
	};
	
	 const handleChangeRowsPerPage = (event) => {
	 setPageSize(parseInt(event.target.value, 5));
	 setCurrentPage(0);
	};
	
	 const CustomPaginationActions = (props) => {
	  
	  console.log('Custom', props)
	  const { count, page, rowsPerPage, onPageChange } = props;
	  // const handleFirstPageButtonClick = (event) => {
	  //   setLoading(true);
	  //   onPageChange(event, 0);
	  //   setTimeout(() => {
	  //     setLoading(false);
	  //   }, 500);
	  // };
	   const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	  };
	
	  // const handleLastPageButtonClick = (event) => {
	  //   setLoading(true);
		// onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	  //   setTimeout(() => {
	  //     setLoading(false);
	  //   }, 500);
	  // };
	   const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	  };
	
	  const handleNextButtonClick = (event) => {
		setLoading(true);
		onPageChange(event, page + 1);
		setTimeout(() => {
		  setLoading(false);
		}, 500);
	  };
	
	  const handleBackButtonClick = (event) => {
		setLoading(true);
		onPageChange(event, page - 1);
		// setTimeout(() => {
		//   setLoading(false);
		// }, 500);
	  };
	
	  return (
		<div
		  style={{
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		  }}
		>
		  <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
			<FirstPageOutlined />
		  </Button>
		  <Button onClick={handleBackButtonClick} disabled={page === 0}>
			<NavigateBefore />
		  </Button>
		  <span className="myPage">{currentPage + 1}</span>
		  <Button
			onClick={handleNextButtonClick}
			disabled={page >= Math.ceil(count / rowsPerPage) - 1}
		  >
			<NavigateNext />
		  </Button>
		  <Button
			onClick={handleLastPageButtonClick}
			disabled={page >= Math.ceil(count / rowsPerPage) - 1}
		  >
			<LastPageOutlined />
		  </Button>
		</div>
	  );
	};

	const currentPageData = filteredData?.slice(
		currentPage * pageSize,
		currentPage * pageSize + pageSize
	  );

	return (
		<div className="mx-6 md:m-10 mt-24 px-6 md:px-10 bg-white rounded-3xl">
		  {/* <Header  title="Banners" /> */}
		    {/* {record ? null : ( */}
			<div className="flex space-x-2 m-4 justify-end">
			  <button onClick={() => addPage()} class="addBtn">
				New Record
			  </button>
			</div>
		  {/* )} */}
		  <div
			style={{
			  display: "flex",
			  justifyContent: "space-between",
			  alignItems: "center",
			  margin: "0 0 20px 0",
			  padding: "8px",
			  borderRadius: "20px",
			  background: "rgb(0, 60, 126)",
			  boxShadow:
				"0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
			}}
		  >
			<h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
			Banners
			</h1>
			<span
			  className="flex space-x-2 m-4 justify-end"
			  style={{ background: "white", borderRadius: "6px" }}
			>
			  <input
				style={{
				  padding: "4px 10px",
				  outline: "none",
				  borderRadius: "6px 0 0 6px",
				  color: "black",
				}}
				type="text"
				placeholder="Search..."
				value={searchQuery}
				onChange={handleSearchChange}
			  />
			  <BiSearch
				style={{
				  fontSize: "25px",
				  color: "gray",
				  cursor: "pointer",
				  margin: "auto",
				  paddingRight: "5px",
				}}
			  />{" "}
			</span>
		  </div>
		  {console.log(tDatas, "tDatas")}
		  <TableContainer className={tDatas.length > 0 ? "table-container" : ""}>
			<Table>
			  <TableHead>
				<TableRow>
				  <TableCell className={classes.cellRow}>Banner Image</TableCell>
				  <TableCell className={classes.cellRow}>Banner Name</TableCell>
				  <TableCell className={classes.cellRow}>Location</TableCell>
				  <TableCell className={classes.cellRow} style={{textAlign: 'start'}}>Actions</TableCell>
				</TableRow>
			  </TableHead>
			  <TableBody>
				{currentPageData ? (
				  currentPageData.map((item, i) => (
					<TableRow key={item._id}>
					  <TableCell className={classes.cell}>{gridImage(item)}</TableCell>
					  <TableCell className={classes.cell}>{item?.bannerName}</TableCell>
					  <TableCell className={classes.cell}>{item?.locationName}</TableCell>
					  <TableCell className={classes.cell}>{gridAction(item)}</TableCell>
					</TableRow>
				  ))
				) : (
				  <TableRow>
					<TableCell colSpan={7} align="center">
					  No Data Available
					</TableCell>
				  </TableRow>
				)}
			  </TableBody>
			</Table>
			{currentPageData?.length > 0 ? (
			  <TablePagination
				count={tDatas.length}
				component="div"
				page={currentPage}
				rowsPerPage={pageSize}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				rowsPerPageOptions={[2]}
				showFirstButton={true}
				showLastButton={true}
				ActionsComponent={CustomPaginationActions}
			  />
			) : null}
		  </TableContainer>
		</div>
	  );
};
export default Banner;
