import React, { useEffect,useRef, useState, useContext } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { Button } from '.';
import { userProfileData } from '../assets/dummy';
import StateContext, { useStateContext } from '../contexts/ContextProvider';
import avatar from '../assets/avatar.jpg';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const UserProfile = () => {
	const { currentColor, isClicked } = useStateContext();
	const [isVisible, setIsVisible] = useState(isClicked.userProfile);
	const popupRef = useRef(null);
	const navigate = useNavigate();
	const { profileData } = useContext(StateContext);


	// useEffect(() => {
	//   fetchProfile();
	// }, []);

	// console.log(data);

	async function logout() {
		// let config={
		//   headers: {
		//     "Content-Type": "application/json",
		//     "x-token": sessionStorage.getItem('token'),
		//   }}
		// await axios.put(`https://api.orbitmart.co.in/api/v1/auth/edit-profile`,{FCM_TOKEN:""},config).then((res)=>{    // })
		navigate('/');
		window.sessionStorage.clear();
		window.location.reload();
	}
	function editProfile() {
		navigate('/editprofile');
		<Button
			icon={<MdOutlineCancel />}
			color="rgb(153, 171, 180)"
			bgHoverColor="light-gray"
			size="2xl"
			borderRadius="50%"
		/>;
	}

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  console.log(isVisible)


	return isVisible ?  (
		<div ref={popupRef} className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
			<div className="flex justify-between items-center">
				<p className="font-semibold text-lg dark:text-gray-200">User Profile</p>
				<Button
					icon={<MdOutlineCancel />}
					onClick={() => setIsVisible(false)}
					color="rgb(153, 171, 180)"
					bgHoverColor="light-gray"
					size="2xl"
					borderRadius="50%"
				/>
			</div>

			<div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
				<img className="rounded-full h-24 w-24" src={profileData.avatar[0]} alt="user-profile" />
				<div>
					<p className="font-semibold text-xl dark:text-gray-200">{`${profileData.firstName} ${profileData.lastName}`}</p>
					<p className="text-gray-500 text-sm dark:text-gray-400"> Administrator </p>
					<p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{profileData.email}</p>
				</div>
			</div>
			<button
				onClick={editProfile}
				style={{
					width: '50%',
					marginLeft: 20,
					backgroundColor: 'lightgreen',
					height: 30,
					color: 'white',
					borderRadius: 8,

					alignItems: 'center',
					alignContent: 'center'
				}}>
				Edit Profile
			</button>
			<button
				onClick={logout}
				style={{
					width: '35%',
					marginLeft: 10,
					backgroundColor: '#ff5c8e',
					height: 30,
					color: 'white',
					borderRadius: 8,

					alignItems: 'center',
					alignContent: 'center'
				}}>
				Logout
			</button>

			{/* <div>
        {userProfileData.map((item, index) => (
          <div
            key={index}
            className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]"
          >
            <button
              type="button"
              style={{ color: item.iconColor, backgroundColor: item.iconBg }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              {item.icon}
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                {item.desc}{" "}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5"></div> */}
			{/* <Button
        color="white"
        bgColor={logoutColor}
        text="Logout"
        borderRadius="10px"
        width="full"
        onClick={() => (window.localStorage.clear(), window.location.reload())}
      /> */}
		</div>
	): null;
};

export default UserProfile;
